<template>
	<div class="outer-wrapper">
		<div class="tours results">
			<div class="search-data" v-if="searchValues">
				<!-- This is done as a dropdown component despite not being used as one (should link instead to the dropdown page) just for contingency if we change our minds -->
				<div class="locations" @click="changeKeyword()">
					<span>
						<span class="keyword" :style="{ 'background-image': `url(${keywordSearchImage})` }" v-html="location">{{ location }}</span>
					</span>
				</div>
				<div class="dates" @click="changeDates()">
					<span class="start">{{formatDate(formattedDates.start)}}</span>
					<img class="center-icon" src="@/assets/flights-search/calendar.png">
					<span class="end">{{formattedDates.end ? formatDate(formattedDates.end) : "--"}}</span>
				</div>
				<!-- <div class="other" @click="viewSummary()">
					<img class="details-icon" width="24px" src="@/assets/flights-search/details.png">
					<span class="pax" v-if="totalPax == 1">{{totalPax}} traveller,</span>
					<span class="pax" v-else>{{totalPax}} travellers,</span>
					<span class="class"> {{flightsSearch.searchValues.cabinClass.label}}</span>
					<div style="clear: both"></div>
				</div> -->
			</div>
			
			<Loader :loading="isLoading"/>
			
			<div v-if="!isLoading">
				<div class="inner-wrapper" v-if="searchResults.length > 0">
					<div class="filter-results">
						<img class="plane-icon" width="24px" src="@/assets/tour-search/tour-map-icon.png" />
						<span class="total-results">
							<span v-if="filteredSearchResults.length < searchResults.length">{{ filteredSearchResults.length }} filtered results from</span><span> {{ searchResults.length }} total results</span>
						</span>
						<router-link :to="{ name: 'Tours Filters' }" id="filter">
							<img class="filter-icon" width="24px" src="@/assets/flights-search/filter-toggles.png" alt="Filter icon">
						</router-link>
					</div>
				</div>

				<!-- Error screen, but no alternative results to display -->
				<!-- <pre>{{ otherSearchResults }}</pre> -->
				<div class="errors notification-action red" v-if="((searchResultsErrors !== null && otherSearchResults.length == 0))">
					<div class="title">Tours not found</div>
					<div class="text">Please try again</div>
					<ResultIcon class="icon" type="neutral" :message="searchResultsErrors || 'Try change search parameters or filters'" />
				</div>	
				<!-- There are results but they have been filtered out -->
				<div class="errors notification-action red" v-else-if="searchResults.length > 0 && filteredSearchResults.length == 0">
					<div class="title">Too many filters</div>
					<div class="text">All results have been filtered out. Try reducing the number of filters.</div>
					<ResultIcon class="icon" type="neutral" message="Try change search parameters or filters" />
				</div>	
				<!-- No results but there are alternative results to display --> 
				<div class="errors notification-action red" v-else-if="searchResults.length == 0 && otherSearchResults.length > 0">
					<div class="title">No results</div>
					<div class="text">There were no results for you search parameters. Either update the search criteria, or view similar results below.</div>
					<ResultIcon class="icon" type="fail" message="View similar results below (may not match your search parameters exactly)" />
				</div>
				<!-- No results AND no alternative results --> 
				<div class="errors notification-action red" v-else-if="searchResults.length == 0 && otherSearchResults.length == 0">
					<div class="title">No results</div>
					<div class="text">There were no results for you search parameters. Try updating your search parameters</div>
					<ResultIcon class="icon" type="fail" message="Try change search parameters" />
				</div>    

				<div class="options" v-if="filteredSearchResults !== []">
					<ToursOption
						class="tour-card"
						v-for="[key, option] in filteredSearchResults"
						v-bind:key="option.id"

						:id="option.id"
						:name="option.name"
						:images="option.images"
						:startLocation="option.start_location"
						:endLocation="option.end_location"
						:length="option.length"
						:description="option.description"
						:fromPrice="option.advertised_price ? option.advertised_price.find(obj => { return obj.currency == 'GBP' }) : false"
					/>
				</div>
				
				<!-- Show alternative search results if they have been provided by the backend, and there are no normal results -->
				<div class="options" v-if="searchResults.length == 0 && otherSearchResults !== []">
					<ToursOption
						class="tour-card"
						v-for="[key, option] in otherSearchResults"
						v-bind:key="option.id"

						:id="option.id"
						:name="option.name"
						:images="option.images"
						:startLocation="option.start_location"
						:endLocation="option.end_location"
						:length="option.length"
						:description="option.description"
						:fromPrice="option.advertised_price ? option.advertised_price.find(obj => { return obj.currency == 'GBP' }) : false"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import router from "@/router";
import locationDropdown from "@/components/locationDropdown.vue";
import ToursOption from "@/components/tours/ToursOption.vue";
import helpers from '@/helpers/helpers.js';
import Loader from '@/components/Loader'
import ResultIcon from '@/components/ResultIcon.vue';

export default {
	name: "toursSearchResults",
	data() {
		return {
			error: false,
			keywordSearchImage: require("@/assets/tour-search/tour-icon.png"),
		};
	},
	components: {
		locationDropdown,
		ToursOption,
		Loader,
		ResultIcon
	},
	computed: {
		...mapState("toursSearch", [
			"searchValues",
			"searchResults",
			"otherSearchResults",
			"filteredSearchResults",
			"searchResultsErrors",
			"isLoading"
		]),
		...mapGetters("toursSearch", [
			"formattedDates",
			"isReturn",
			"totalPax",
			"bestResults",
			"searchFormErrors"
		]),
		location() {
			if(this.searchValues.keyword.name) {
				return this.searchValues.keyword.name
			}
			return '<span style="color: grey;">Enter Search...<span>';
		},
	},
	methods: {
		init() {
			this.mapQueryParams();
			if(this.searchFormErrors && !this.searchFormErrors.size > 0) {
				this.submitTourSearch();
			}
		},
		...mapActions({
			submitTourSearch: "toursSearch/submitTourSearch",
			mapQueryParams: 'toursSearch/mapQueryParams',
		}),
		changeKeyword() {
			router.push({
				name: 'Tours Keyword',
				query: {
					trip_id: this.$route.query.trip_id,
					trip_item_id: this.$route.query.trip_item_id,
				}
			})
			e.stopPropagation();
		},
		changeDates() {
			router.push({
				name: 'Tours Dates',
				query: {
					trip_id: this.$route.query.trip_id,
					trip_item_id: this.$route.query.trip_item_id,
				}
			})
			e.stopPropagation();
		},
		viewSummary() {
			router.push({
				name: 'Tours Summary',
				query: {
					trip_id: this.$route.query.trip_id,
					trip_item_id: this.$route.query.trip_item_id,
				}
			})
			
		},
		formatDate: (date) => helpers.formatDateWithoutDay(date),
	},
	created() {
		this.init();
	}
};
</script>

<style scoped>

.disabled {
	pointer-events: none;
}
.tour-card {
	margin-bottom: 20px;
}

.results .errors {
	padding-top: 25px;
	margin-bottom: 25px;
}
</style>